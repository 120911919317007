<template>
  <header class="p-3 text-bg-dark">
    <div class="container">
      <div
        class="
          d-flex
          flex-wrap
          align-items-center
          justify-content-center justify-content-lg-start
        "
      >
        <a
          href="/"
          class="
            d-flex
            align-items-center
            mb-2 mb-lg-0
            text-white text-decoration-none
          "
        >
          <svg
            class="bi me-2"
            width="40"
            height="32"
            role="img"
            aria-label="Bootstrap"
          >
            <use xlink:href="#bootstrap"></use>
          </svg>
        </a>

        <ul
          class="
            nav
            col-12 col-lg-auto
            me-lg-auto
            mb-2
            justify-content-center
            mb-md-0
          "
        >
          <li>
            <router-link
              :to="{ name: 'home' }"
              class="vr-1 nav-link px-2 text-white"
              >Home</router-link
            >
          </li>
          <li>
            <a href="#" class="vr-1 nav-link px-2 text-white">Features</a>
          </li>
          <li><a href="#" class="vr-1 nav-link px-2 text-white">Pricing</a></li>
          <li><a href="#" class="vr-1 nav-link px-2 text-white">FAQs</a></li>
          <li><a href="#" class="vr-1 nav-link px-2 text-white">About</a></li>
        </ul>

        <div class="text-end">
          <!--
          <button type="button" class="btn btn-outline-light me-2">
            نسخه آزمایشی
          </button>
        -->
          <button type="button" class="btn btn-warning per-font">
            ورود یا ثبت‌نام
            <i class="bi bi-person-fill"></i>
          </button>
          <span
            class="badge rounded-pill mx-2 bg-dark"
            style="font-family: 'Vazirmatn FD' !important"
          >
            نسخه آزمایشی در حال توسعه
            {{ appVersion }}
          </span>
        </div>
      </div>
    </div>
  </header>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      appVersion: this.$store.state.appVersion,
    };
  },
};
</script>

<style>
#app {
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
p {
  font-family: "Noto Sans", sans-serif;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983 !important;
}

li.vr-1.router-link-exact-active {
  color: #42b983 !important;
}

.per-font {
  font-family: "Vazirmatn" !important;
}
.per-font-fd {
  font-family: "Vazirmatn FD" !important;
}

.bg-s-contain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.bg-s-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  background-color: rgba(249, 249, 249, 0.98);
  /*box-shadow: 0 -5px 10px 0 rgb(0 0 0 / 6%);*/
  min-height: 60px;
  display: flex;
  text-align: center;
  transition: all 350ms ease;
}
.shadow-1 {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #efecf3 !important;
  border-radius: 0.75rem;
  box-shadow: 0 0.125rem 0.125rem -0.125rem rgb(31 27 45 / 8%),
    0 0.25rem 0.75rem rgb(31 27 45 / 8%) !important;
  transition: all 0.4s ease-in-out;
}
</style>
