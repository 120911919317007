export const routes = (parentName) => {
  return [
    {
      path: "special-english",
      name: `${parentName}-Special-English-Index`,
      component: () => import("./Index.vue"),
      //redirect: {name: `${parentName}-Preferences-General`},
      children: [
        {
          path: "",
          name: `${parentName}-Special-English`,
          component: () => import("./List.vue"),
        },
        {
          path: ":category",
          name: `${parentName}-Special-English-Category`,
          component: () => import("./Category.vue"),
        },

        {
          path: ":category/:id",
          name: `${parentName}-Special-English-Lesson`,
          component: () => import("./Lesson.vue"),
        },
      ],
    },
  ];
};
