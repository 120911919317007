<template>
    <div class="container px-4 py-5">
      <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>