import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "bootstrap-icons/font/bootstrap-icons.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "vazirmatn/Vazirmatn-font-face.css";
import "vazirmatn/misc/Farsi-Digits/Vazirmatn-FD-font-face.css";

import { version as appVersion } from "../package.json";

store.commit("setAppVersion", appVersion);

createApp(App).use(store).use(router).mount("#app");
