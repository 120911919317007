import store from "@/store";
import layout from "@/layouts/Public";

import { routes as routesSpecialEnglish } from "./special-english/Routes";



const groupName = 'lessons'; /* نام گروه کابری برای مدیریت داشبرد یا پنل مورد نظر */
const pathRoot = '/lessons/'; /* مسیری ریشه‌ای که برای گروه کاربری در نظر گرفته‌ایم */
const parentName = 'Lessons'; /* نام اصلی برای زیر مسیر‌های مدیریت داشبرد یا پنل مورد نظر */
// ------- Routes -------- -------- -------- -------- -------- -------- -------- //
export const routes = [
  {
    name: parentName,
    path: pathRoot,
    component: layout,
    meta: {
      requiresAuth: false,
      group: groupName,
    },
    children: [
      ...routesSpecialEnglish(parentName),


    ],
  },
];

// ------- END Routes -------- -------- -------- -------- -------- -------- -------- //