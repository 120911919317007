<template>
  <div class="home">
    <div class="container col-xxl-8 px-4 py-5">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div class="col-12 col-sm-12 col-lg-6">
          <img
            src="/static/img/jpg/lewis-keegan-oB2aEeE8s4A-unsplash.jpg"
            class="d-block mx-lg-auto img-fluid"
            style="border-radius: 7px"
            alt="Bootstrap Themes"
            width="700"
            height="500"
            loading="lazy"
          />
        </div>
        <div class="col-lg-6">
          <h1 class="per-font fw-bold lh-1 mb-3" style="font-size: 35px">
            جایی برای خواندن متن‌های انگلیسی
          </h1>
          <p class="per-font lead">
            تلاش شده اینجا متن‌های انگلیسی همراه با صوت‌های همراه در اختیار
            کاربران قرار بگیره
          </p>
          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <button type="button" class="btn btn-primary btn-lg px-4 me-md-2">
              Primary
            </button>
            <button type="button" class="btn btn-outline-secondary btn-lg px-4">
              Default
            </button>
          </div>
        </div>
      </div>

      <h2 class="pb-2 border-bottom">Custom cards</h2>

      <div
        class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5"
        style="text-align: center"
      >
        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-bg-dark
              rounded-4
              shadow-lg
              bg-s-cover
            "
            style="
              border: unset;
              background-image: url('/static/img/jpg/timo-wagner-fT6-YkB0nfg-unsplash.jpg');
            "
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <router-link
                :to="{ name: 'Lessons-Special-English' }"
                class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold"
                style="text-decoration: none; color: #fff"
              >
                Special English
              </router-link>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>

        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-bg-dark
              rounded-4
              shadow-lg
              bg-s-cover
            "
            style="
              border: unset;
              background-image: url('https://getbootstrap.com/docs/5.2/examples/features/unsplash-photo-2.jpg');
            "
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                504 absolutely essential words
              </h3>
              <ul class="d-flex list-unstyled mt-auto">
                <li class="me-auto">
                  <img
                    src="https://github.com/twbs.png"
                    alt="Bootstrap"
                    width="32"
                    height="32"
                    class="rounded-circle border border-white"
                  />
                </li>
                <li class="d-flex align-items-center me-3">
                  <svg class="bi me-2" width="1em" height="1em">
                    <use xlink:href="#geo-fill"></use>
                  </svg>
                  <small>Pakistan</small>
                </li>
                <li class="d-flex align-items-center">
                  <svg class="bi me-2" width="1em" height="1em">
                    <use xlink:href="#calendar3"></use>
                  </svg>
                  <small>4d</small>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-bg-dark
              rounded-4
              shadow-lg
              bg-s-cover
            "
            style="
              border: unset;
              background-image: url('https://getbootstrap.com/docs/5.2/examples/features/unsplash-photo-3.jpg');
            "
          >
            <div class="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
              <h3 class="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                Another longer title belongs here
              </h3>
              <ul class="d-flex list-unstyled mt-auto">
                <li class="me-auto">
                  <img
                    src="https://github.com/twbs.png"
                    alt="Bootstrap"
                    width="32"
                    height="32"
                    class="rounded-circle border border-white"
                  />
                </li>
                <li class="d-flex align-items-center me-3">
                  <svg class="bi me-2" width="1em" height="1em">
                    <use xlink:href="#geo-fill"></use>
                  </svg>
                  <small>California</small>
                </li>
                <li class="d-flex align-items-center">
                  <svg class="bi me-2" width="1em" height="1em">
                    <use xlink:href="#calendar3"></use>
                  </svg>
                  <small>5d</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
</script>
